export const darkTheme = {
  background: "#1b1d21",
  text: "#b6b7b8",
  border: "#646568",
}

export const whiteTheme = {
  background: "#ffffff",
  text: "#232323",
  border: "#efefef",
}
